<template>
	<v-container v-if="$can('view', 'Page')" fluid>
		<v-row>
			<v-col cols="12">
				<v-card v-if="!isFetchingData" class="pb-6">
					<v-tabs
						v-model="galleryTabs"
						background-color="transparent"
						left
					>
						<v-tab>Pictures</v-tab>
						<v-tab>Videos</v-tab>
					</v-tabs>

					<v-tabs-items class="px-10 mt-4" v-model="galleryTabs">
						<v-tab-item
							transition="fade-transition"
							reverse-transition="fade-transition"
						>
							<Pictures :pictures="pictures" />
						</v-tab-item>
						<v-tab-item
							transition="fade-transition"
							reverse-transition="fade-transition"
						>
							<Videos :videos="videos" />
						</v-tab-item>
					</v-tabs-items>
					<SensitiveContent :payload="sensitiveContent" />
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Pictures from './components/picture/Pictures'
import Videos from './components/video/Videos'
import SensitiveContent from './components/SensitiveContent'
// import apiData from './data'
export default {
	components: {
		Pictures,
		Videos,
		SensitiveContent,
	},
	data() {
		return {
			pictures: {},
			videos: {},
			sensitiveContent: null,
			galleryTabs: null,
			isFetchingData: true,
		}
	},
	mounted() {
		this.getGalleryData()
	},
	methods: {
		getGalleryData() {
			// const res = apiData
			// this.pictures = res.meta.pictures
			// this.videos = res.meta.videos
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/pages/gallery`,
			})
				.then((res) => {
					this.pictures = res.data.meta.pictures
					this.videos = res.data.meta.videos
					this.sensitiveContent = res.data.meta.sensitiveContent
					this.isFetchingData = false
				})
				.catch(() => {
					this.isFetchingData = false
				})
		},
	},
}
</script>
